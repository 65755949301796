import a1 from '../assets/a1.png'
import a2 from '../assets/a2.png'
import a3 from '../assets/a3.png'
import a4 from '../assets/a4.png'
import a5 from '../assets/a5.png'
import a6 from '../assets/a6.png'
import a7 from '../assets/a7.png'
import a8 from '../assets/a8.png'
import a9 from '../assets/a9.png'
import a10 from '../assets/a10.png'
import a11 from '../assets/a11.png'
import a12 from '../assets/a12.png'
import a13 from '../assets/a13.png'
import a14 from '../assets/a14.png'
import a15 from '../assets/a15.png'
import a16 from '../assets/a16.png'
import a18 from '../assets/a18.png'
import a19 from '../assets/a19.png'
import a20 from '../assets/a20.png'
import a21 from '../assets/a21.png'
import a22 from '../assets/a22.png'
import a23 from '../assets/a23.png'

import a25 from '../Project_Info/p2.png';
import a26 from '../Project_Info/p3.png';
import a29 from '../Project_Info/p6.png';
import a32 from '../Project_Info/p9.png';
import a34 from '../Project_Info/p11.png';
import a35 from '../Project_Info/p12.png';
import a36 from '../Project_Info/p13.png';


const ImagesData = [
  {
    img: a34,
    alt: "Reload page.",
    key: '34'
  },
  {
    img: a25,
    alt: "Reload page.",
    key: '25'
  },
  {
    img: a26,
    alt: "Reload page.",
    key: '26'
  },
  
  {
    img: a29,
    alt: "Reload page.",
    key: '29'
  },
  {
    img: a32,
    alt: "Reload page.",
    key: '32'
  },
  {
    img: a35,
    alt: "Reload page.",
    key: '35'
  },
  {
    img: a36,
    alt: "Reload page.",
    key: '36'
  },
{
  img: a23,
  alt: "Reload page.",
  key: '23'
},
{
  img: a20,
  alt: "Reload page.",
  key: '20'
},
{
  img: a13,
  alt: "Reload page.",
  key: '13'
},
{
  img: a16,
  alt: "Reload page.",
  key: '16'
},
{
  img: a5,
  alt: "Reload page.",
  key: '1'
},
{
  img: a21,
  alt: "Reload page.",
  key: '21'
},
{
  img: a22,
  alt: "Reload page.",
  key: '22'
},
{
  img: a18,
  alt: "Reload page.",
  key: '18'
},
{
  img: a15,
  alt: "Reload page.",
  key: '15'
},
{
  img: a14,
  alt: "Reload page.",
  key: '14'
},
{
  img: a10,
  alt: "Reload page.",
  key: '10'
},
{
  img: a11,
  alt: "Reload page.",
  key: '2'
},
{
  img: a12,
  alt: "Reload page.",
  key: '12'
},
{
  img: a2,
  alt: "Reload page.",
  key: '11'
},
{
  img: a3,
  alt: "Reload page.",
  key: '3'
},
{
  img: a4,
  alt: "Reload page.",
  key: '4'
},
{
  img: a1,
  alt: "Reload page.",
  key: '5'
},
{
  img: a6,
  alt: "Reload page.",
  key: '6'
},
{
  img: a7,
  alt: "Reload page.",
  key: '7'
},
{
  img: a8,
  alt: "Reload page.",
  key: '8'
},
{
  img: a9,
  alt: "Reload page.",
  key: '9'
},
{
  img: a19,
  alt: "Reload page.",
  key: '19'
}
]
export { ImagesData }
